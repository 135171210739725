<template>
  <div class="pipe">
    <div class="pipe-box">
      <div class="pipe-item pepe-left">
        <div class="btns">
          <el-button type="primary" :disabled="isDisabled"  size="small" icon="el-icon-plus" @click="onAdd(1)">新增</el-button>
          <span style="margin-left:10px">未配置默认:直线2.5出来</span>
        </div>
        <el-table
          :data="steelTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="镀锌">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in steeloptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in steeloptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="线形">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor" style="width: 60px;overflow: hidden;margin-left: 20px;">{{ row.lineTypeName }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineTypeCode"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="线宽">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.lineWidth }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineWidth"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineWidthOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" width="150" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="steelEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 1)"> 删除 </span>
               
              </div>
              <div v-else class="tableBtn">
                  <span class="btn" style="color: #1e9fff" v-if="!row.id"  @click="onSave(row, $index, 1)"> 保存 </span>
                  <span class="btn" style="color: #1e9fff" v-else @click="update(row, $index, 1)"> 保存 </span>
                  <span class="btn"  @click="steelCancel(row, $index)"> 取消 </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
        <div class="pipe-item pepe-right" style="margin-right:10px">
        <div class="btns">
          <el-button type="primary" :disabled="isdxDisabled"  size="small" icon="el-icon-plus"  @click="onAdd(3)">新增</el-button> 
          <span style="margin-left:10px">未配置默认:直线2.5出来</span>
        </div>
        <el-table
          :data="dxTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="钢管规格">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dxptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in peptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="线形">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor" style="width: 60px;overflow: hidden;margin-left: 20px;">{{ row.lineTypeName }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineTypeCode"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="线宽">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.lineWidth }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineWidth"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineWidthOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" width="100" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="peEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 3)"> 删除 </span>
                
              </div>
           <div v-else  class="tableBtn">
                <span class="btn" style="color: #1e9fff" v-if="!row.id"  @click="onSave(row, $index, 3)"> 保存 </span>
                  <span class="btn" style="color: #1e9fff" v-else @click="update(row, $index, 3)"> 保存 </span>
                  <span class="btn"   @click="steelCancel(row, $index)"> 取消 </span>
           
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pipe-item pepe-right" style="margin-right:10px">
        <div class="btns">
          <el-button type="primary" :disabled="isPeDisabled"  size="small" icon="el-icon-plus"  @click="onAdd(2)">新增</el-button> 
          <span style="margin-left:10px">未配置默认:直线2.5出来</span>
        </div>
        <el-table
          :data="peTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%;height:calc(100vh - 300px);overflow: auto;"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictNames" align="center" label="PE管">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.dictName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in peptions"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodes"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in peptionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="showName" align="center" label="线形">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor" style="width: 60px;overflow: hidden;margin-left: 20px;">{{ row.lineTypeName }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineTypeCode"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value.toString()"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="线宽">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.lineWidth }}</div>
              <div v-else>
                <el-select
                  v-model="row.lineWidth"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in lineWidthOpton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" width="100" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="peEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row, 2)"> 删除 </span>
                
              </div>
           <div v-else  class="tableBtn">
                <span class="btn" style="color: #1e9fff" v-if="!row.id"  @click="onSave(row, $index, 2)"> 保存 </span>
                  <span class="btn" style="color: #1e9fff" v-else @click="update(row, $index, 2)"> 保存 </span>
                  <span class="btn"   @click="steelCancel(row, $index)"> 取消 </span>
           
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {drawing_queryConfig,drawing_getNotUsedDictList,drawing_save,drawing_update,drawing_delete} from '../apis/base'
export default {
  data() {
    return {
      steelTableData: [],
      peTableData: [],
      dxTableData: [],
      steeloptions: [],
      steeloptionsAlter: [],
      peptions: [],
      peptionsAlter: [],
      dxptions: [],
      dxptionsAlter: [],
      copyRow: new Map(),
      peCopyRow: new Map(),
      dxCopyRow: new Map(),
      lineOpton:[{value:'1',label:'直线___________'},{value:'2',label:'虚线------------'}],
      lineWidthOpton:[{value:'1.0',label:'1.0'},{value:'2.0',label:'2.0'},{value:'3.0',label:'3.0'},{value:'4.0',label:'4.0'},{value:'5.0',label:'5.0'}],
    }
  },
  computed: {
    isDisabled() {
      if (!this.steelTableData.length) return false
      let list = this.steelTableData.filter(el => el.id)
      return list.length !== this.steelTableData.length || !this.steeloptions.length
    },
    isPeDisabled() {
      if (!this.peTableData.length) return false
      let list = this.peTableData.filter(el => el.id)
      return list.length !== this.peTableData.length || !this.peptions.length
    },
    isdxDisabled() {
      if (!this.dxTableData.length){return false;}
      let list = this.dxTableData.filter(el => el.id)
      return list.length !== this.dxTableData.length || !this.dxptions.length
    }
  },
  mounted() {
    this.getDictionaries()
    this.getList()
    this.getPeDictionaries()
    this.getPeList()
    this.getdxDictionaries()
    this.getdxList()
  },
  methods: {
    /*
     *@description: 获取字典
     *@author: 黄丽玲
     */
 
    async getDictionaries() {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240001'})
      this.steeloptions = res.data
    },
 
    async getList() {
      let res = await drawing_queryConfig({ pidDictCode: '1000240001'})
      this.steelTableData = res.data
    },
   
    async getPeDictionaries() {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240002' })
      this.peptions = res.data
    },
    
    async getPeList() {
      let res = await drawing_queryConfig({ pidDictCode: '1000240002' })
      this.peTableData = res.data
    },
    async getdxDictionaries() {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240003' })
      this.dxptions = res.data
    },
    
    async getdxList() {
      let res = await drawing_queryConfig({ pidDictCode: '1000240003' })
      this.dxTableData = res.data
    },
    isEditor(row) {
      return row.id != '' && row.id != null && !row.isEditor
    },
    // 钢管新增
    onAdd(type) {
      let obj = {
        dictCodes: [],
        lineTypeCode: '1',
        lineWidth: '2.0',
        id: null,
        pidDictCode: type == 1 ? '1000240001' : '1000240002',
        isEditor: true
      }
      if (type == 1) {
        this.steelTableData.push(obj)
      } else if(type==2) {
        this.peTableData.push(obj)
      }else{
        this.dxTableData.push(obj)
      }
    },
    // 钢管编辑
    async steelEditor(row) {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240001'})
      res.data.forEach(({ dictCode, dictName }) => {
        if (!this.copyRow.has(dictCode)) {
          this.copyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.steeloptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.copyRow.has(el)) {
          this.copyRow.set(el,dictNameA[index])
        }
      
      this.steeloptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
       this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      row.lineTypeCode = row.lineTypeCode.toString()
      this.$set(row, 'isEditor', true)
    },
    async peEditor(row) {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240002' })
       res.data.forEach(({ dictCode, dictName }) => {
        if (!this.peCopyRow.has(dictCode)) {
          this.peCopyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.peptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.peCopyRow.has(el)) {
          this.peCopyRow.set(el,dictNameA[index])
        }
      this.peptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
      this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      row.lineTypeCode = row.lineTypeCode.toString()
      this.$set(row, 'isEditor', true)
    },
     async dxEditor(row) {
      let res = await drawing_getNotUsedDictList({ pidDictCode: '1000240003' })
       res.data.forEach(({ dictCode, dictName }) => {
        if (!this.dxCopyRow.has(dictCode)) {
          this.dxCopyRow.set(dictCode, dictName)
        }
      })
      let dictCodeA = row.dictCode.split(',');
      let dictNameA = row.dictName.split(',');
      this.dxptionsAlter = res.data;
      dictCodeA.forEach((el,index)=>{
        if (!this.dxCopyRow.has(el)) {
          this.dxCopyRow.set(el,dictNameA[index])
        }
      this.dxptionsAlter.push({dictCode:el,dictName:dictNameA[index]})
      })
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
      this.$set(row, 'dictCodes', dictCodeA)
      row.dictCodes = dictCodeA
      row.lineTypeCode = row.lineTypeCode.toString()
      this.$set(row, 'isEditor', true)
    },
    steelCancel(row, index) {
      if (row.id) {
        let { copyRow } = this
        let res = copyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.copyRow.delete(row.id)
      } else {
        this.steelTableData.splice(index, 1)
      }
    },
    peCancel(row, index) {
      console.log(this)
      if (row.id) {
        
        let { peCopyRow } = this
        let res = peCopyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.peCopyRow.delete(row.id)
      } else {
        this.peTableData.splice(index, 1)
      }
    },
    dxCancel(row, index) {
      if (row.id) {
        console.log(this)
        let { dxCopyRow } = this
        let res = dxCopyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.dxCopyRow.delete(row.id)
      } else {
        this.dxTableData.splice(index, 1)
      }
    },
    async update(row, index, type) {
      if(row.dictCodes.length<=0){
        this.$message.error('请选择规格')
        return
      }
      let data = {}
      data.dictCodes=row.dictCodes.toString();
        data.lineTypeCode=row.lineTypeCode;
        data.lineWidth=row.lineWidth;
        data.pidDictCode= type == 1 ? '1000240001' : '1000240002';
        data.id = row.id
        if(type==3){
          data.pidDictCode = '1000240003'
        }
        if(type==2){
          data.pidDictCode = '1000240002'
        }
      row.isEditor = false
      let res = await drawing_update(data)
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else {
        this.getPeDictionaries()
        this.getPeList()
      }
    },
   
    async onSave(row, index, type) {
      if(row.dictCodes.length<=0){
        this.$message.error('请选择规格')
        return
      }
        let data = {}
        data.dictCodes=row.dictCodes.toString();
        data.lineTypeCode=row.lineTypeCode;
        data.lineWidth=row.lineWidth;
        data.pidDictCode= type == 1 ? '1000240001' : '1000240002';
         if(type==3){
          data.pidDictCode = '1000240003'
        }
        if(type==2){
          data.pidDictCode = '1000240002'
        }
        data.id = ''
      let res = await drawing_save(data)
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else if(type == 2) {
        this.getPeDictionaries()
        this.getPeList()
      }else{
        this.getdxDictionaries()
        this.getdxList()
      }
      row.isEditor = false
    },
   
    async getDelete(row, type) {
      let res = await drawing_delete({ id: row.id })
      if (type == 1) {
        this.getDictionaries()
        this.getList()
      } else if(type == 2) { 
        this.getPeDictionaries()
        this.getPeList()
      } else {
        this.getdxDictionaries()
        this.getdxList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pipe{
    background-color: #fff;
    padding: 10px 15px;
    border-top: #eeeeee 1px solid;
}
.pipe-box {
  display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pipe-item {
    flex: 1;
  }
  .pepe-left {
    margin-right: 10px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 2px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
</style>
